import { range } from 'lodash-es'

import { MemoryLayout, StudyUserWithMetadata } from '#types'

export const appFlavors: any = [
  {
    title: 'Debug',
    value: 'debug',
  },
  {
    title: 'Research',
    value: 'research',
  },
  {
    title: 'Experimental',
    value: 'experimental',
  },
  {
    title: 'Release',
    value: 'release',
  },
]

// range() is not end inclusive, so sizes actually 4-15
export const ringSizes = range(4, 15 + 1).map((number) => ({ title: String(number), value: number }))

export const configModes: any = [
  {
    title: 'Manual',
    value: 'manual',
  },
  {
    title: 'Monitor',
    value: 'monitor',
  },
]

export const fileHeaders: any = [
  {
    key: 'name',
    title: 'Name',
  },
  {
    key: 'data.hardware_type',
    title: 'Hardware',
  },
  {
    key: 'createdBy',
    title: 'Creator',
  },
  {
    key: 'createdAt',
    title: 'Created',
  },
  {
    key: 'updatedAt',
    title: 'Updated',
    align: 'end',
  },
  {
    key: 'actions',
    title: '',
    align: 'end',
    sortable: false,
    width: 80,
  },
]

export const studyHeaders: any = [
  {
    key: 'name',
    title: 'Name',
  },
  {
    key: 'appFlavor',
    title: 'App',
  },
  {
    key: 'fwVersion',
    title: 'Min FW ver.',
  },
  {
    key: 'ringSizes',
    title: 'Ring sizes',
  },
  {
    key: 'studyFiles',
    title: 'Files',
  },
  {
    key: 'studyUsers',
    title: 'Users',
  },
  {
    key: 'createdAt',
    title: 'Created',
  },
  {
    key: 'updatedAt',
    title: 'Updated',
    align: 'end',
  },
  {
    key: 'actions',
    title: '',
    align: 'end',
    sortable: false,
    width: 80,
  },
]

export const protoHeaders: any = [
  {
    key: 'name',
    title: 'Name',
  },
  {
    key: 'activities',
    title: 'Activities',
  },
  {
    key: 'createdBy',
    title: 'Creator',
  },
  {
    key: 'createdAt',
    title: 'Created',
  },
  {
    key: 'updatedAt',
    title: 'Updated',
    align: 'end',
  },
  {
    key: 'actions',
    title: '',
    align: 'end',
    sortable: false,
    width: 80,
  },
]

export const configHeaders: any = [
  {
    title: '',
    key: 'data-table-expand',
  },
  {
    key: 'name',
    title: 'Config name',
  },
  {
    key: 'hw',
    title: 'HW Type',
  },
  {
    key: 'config',
    title: 'Configuration',
  },
  {
    key: 'estimation',
    title: 'Recording time estimation (memory layout)',
    align: 'end',
  },
  {
    key: 'actions',
    title: '',
    align: 'end',
    sortable: false,
    width: 80,
  },
]

const warningsSortNumber = (warnings: StudyUserWithMetadata['warnings']) => {
  const { hasNoOrbUploads, hasSmallUploads } = warnings
  if (hasNoOrbUploads) {
    return 2
  } else if (hasSmallUploads) {
    return 1
  }
  return 0
}

export const usersHeaders: any = [
  {
    title: '',
    key: 'data-table-expand',
  },
  {
    key: 'userUid',
    title: 'User UID',
  },
  {
    key: 'ringSize',
    title: 'Ring size',
  },
  {
    key: 'appVersion',
    title: 'App version',
  },
  {
    key: 'firmwareVersion',
    title: 'Firmware version',
  },
  {
    key: 'schedule',
    title: 'Auto schedule',
  },
  {
    key: 'fetchedAt',
    title: 'Last fetched config',
  },
  {
    key: 'lastOrbUpload',
    title: 'Last ORB upload',
  },
  {
    key: 'warnings',
    title: '',
    sort: (a: StudyUserWithMetadata['warnings'], b: StudyUserWithMetadata['warnings']) => {
      return warningsSortNumber(b) - warningsSortNumber(a)
    },
  },
  {
    key: 'actions',
    title: '',
    align: 'end',
    sortable: false,
    width: 80,
  },
]

export const protocolHeaders: any = [
  {
    title: '',
    key: 'data-table-expand',
  },
  {
    key: 'name',
    title: 'Protocol name',
  },
  {
    key: 'count',
    title: 'Activities count',
  },
  {
    key: 'addedAt',
    title: 'Added to the study',
    align: 'end',
  },
  {
    key: 'actions',
    title: '',
    align: 'end',
    sortable: false,
    width: 80,
  },
]

export const teamUsersHeaders: any = [
  {
    key: 'userUid',
    title: 'User UID',
  },
  {
    key: 'status',
    title: 'Status',
  },
  {
    key: 'studyId',
    title: 'Study ID',
    sortable: false,
  },
  {
    key: 'data-table-select',
    title: '',
  },
]

export const notFoundUsersHeaders: any = [
  {
    key: 'userUid',
    title: 'User UID',
  },
  {
    key: 'status',
    title: 'Status',
  },
]

export const scheduleHeaders: any = [
  {
    key: 'uuid',
    title: 'User UID',
  },
  {
    key: 'time',
    title: 'Scheduled time',
    align: 'end',
  },
  {
    key: 'duration',
    title: 'Recording time',
    align: 'end',
  },
  /* {
    key: 'collectedAt',
    title: 'Last data collection',
    align: 'end',
  }, */
  {
    key: 'actions',
    title: '',
    align: 'end',
    sortable: false,
    width: 80,
  },
]

export const orbFilesHeaders: any = [
  {
    key: 'label',
    title: 'Label',
    filterable: false,
  },
  {
    key: 'fw',
    title: 'FW Version',
    filterable: false,
    cellClass: 'text-no-wrap',
  },
  {
    key: 'collectionId',
    title: 'Config File',
    cellClass: 'text-no-wrap',
    filterable: false,
  },
  {
    key: 'started',
    title: 'Recording Started',
    cellClass: 'text-no-wrap',
    filterable: false,
  },
  {
    key: 'uploadedAt',
    width: 200,
    title: 'Uploaded',
    cellClass: 'text-no-wrap',
    filterable: false,
    align: 'end',
  },
  {
    key: 'actions',
    title: '',
    width: 80,
    align: 'end',
    sortable: false,
  },
]

export const jzlogFilesHeaders: any = [
  {
    key: 'type',
    title: 'File type',
    filterable: false,
    cellClass: 'text-capitalize',
  },
  {
    key: 'size',
    title: 'File size',
    filterable: false,
  },
  {
    key: 'lastModified',
    title: 'Last modified',
    cellClass: 'text-no-wrap',
    filterable: false,
    align: 'end',
  },
  {
    key: 'actions',
    title: '',
    width: 80,
    align: 'end',
    sortable: false,
  },
]

export const rolloutHeaders: any = [
  {
    key: 'value.type',
    title: 'Hardware',
  },
  {
    key: 'value.version',
    title: 'FW version',
  },
  {
    key: 'state',
    title: 'Rollout state',
  },
  {
    key: 'created_at',
    title: 'Rollout created',
    cellClass: 'text-no-wrap',
  },
  {
    key: 'actions',
    align: 'end',
    title: 'Actions',
    sortable: false,
  },
]

export const pagesPerSecond: any = {
  PPG: {
    4000: 1.454,
    5000: 1.144,
    6667: 0.858,
    10000: 0.572,
    20000: 0.286,
  },
  ECG: {
    4000: 1.454,
    5000: 1.144,
    6667: 0.858,
    10000: 0.572,
    20000: 0.286,
  },
  BIOZ: {
    4000: 1.454,
    5000: 1.144,
    6667: 0.858,
    10000: 0.572,
    20000: 0.286,
  },
  ACM: {
    20000: 0.82,
    80000: 0.205,
  },
  Gyro: {
    20000: 0.82,
    80000: 0.205,
  },
  Temperature: {
    100000: 0.588,
    10000000: 0.006,
    60000000: 0.001,
  },
}

// Ring memory layouts as specified in Ring API
export const memoryLayouts: MemoryLayout[] = [
  { title: '8.0 / 8.0 MiB (Gen3 default)', rdataSize: 8192, jzlog: 8192, ringApiValue: 0 },
  { title: '1.0 / 15.0 MiB (Gen4 default)', rdataSize: 1024, jzlog: 15360, ringApiValue: 0 },
  { title: '15.6 / 0.4 MiB', rdataSize: 15974.4, jzlog: 400, ringApiValue: 1 },
  { title: '15.0 / 1.0 MiB', rdataSize: 15360, jzlog: 1024, ringApiValue: 2 },
  { title: '14.0 / 2.0 MiB', rdataSize: 14336, jzlog: 2048, ringApiValue: 3 },
  { title: '12.0 / 4.0 MiB', rdataSize: 12288, jzlog: 6144, ringApiValue: 4 },
]

export const hwTypesForRollouts: any = [
  {
    title: 'Bentley',
    value: 'bentley',
  },
  {
    title: 'Cooper',
    value: 'cooper',
  },
  {
    title: 'Gen3',
    value: 'gen2x',
  },
  {
    title: 'Oreo',
    value: 'oreo',
  },
]
