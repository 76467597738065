import { Activity, AutoSchedule, Flag, MemoryLayout, StudyFile, StudyProtocol, StudyUser } from '#types'

export type UserRights = 'allowDataDownloadAccess'

export interface StatsPrefs {
  collapsedCards: string
}

export interface WarrantyReasonStats {
  warrantyReasons: {
    count: number
    reason: string
  }[]
}

export interface CreateTaskParameter {
  scheduleDescription: string
  cronSchedule: string
  jobs: Job[]
}

export interface EditTaskParameter {
  state?: string
  ruleName: string
  taskName: string
  scheduleDescription?: string
  cronSchedule?: string
  parameters?: any
  info?: any
}

export interface Job {
  jobType: string
  containerOverrides: {
    environment: Environment[]
  }
}

export interface Environment {
  name: string
  value: string
}

export interface FirmwareAlert {
  versions: string[]
  alert: string
}

export interface SearchInput {
  appFlavor?: string
  onlyActive?: string
  labelListId?: string
  sampleListId?: string
  dataEndDate?: string
  dataStartDate?: string
  userEndDate?: string
  userStartDate?: string
}

export interface SummaryJson {
  fw_versions: string[]
  os_versions: string[]
  fw_summaries: FWSummary[]
  temperature_summary: any
  failed_runs: number
  successful_runs: number
}

export interface FWSummary {
  fw: string
  rings_with_fw: number
  os_summaries: OSSummary[]
  dump_summary: any
}

export interface OSSummary {
  os: string
  nights: number
  rings: number
  event_summaries: EventSummary
  battery_slope_summary: BatterySlopeSummary | null
}

export interface BatterySlopeSummary {
  failure: BatterySlopeStatus
  warning: BatterySlopeStatus
  slope_average: number
  note: BatterySlopeStatus | null
  ok: BatterySlopeStatus | null
  worst_cases?: BatterySlopeWorstCase[] | null
}

export type BatterySlopeWorstCase = {
  user_uid?: string
  uid?: string
  average: number
}

export interface EventSummary {
  [key: string]: EventSummaryData[]
}

export type EventKeys = 'FAILURE' | 'WARNING' | 'NOTE'

export interface EventSummaryData {
  name: string
  count: number
  distinct_ring_count: number
  count_per_ring: number
  count_per_night: number
  count_per_distinct_ring: number
  top_occurrences: TopOccurrence[]
  order?: number
  event?: string
  changes_per_night?: string
}

export interface CoredumpSummary {
  dumps: CoredumpsSummary[]
  total_count: number
  individual_count: number
  incomplete_count: number
}

export interface CoredumpsSummary {
  error: string
  failed_in: any
  link_register: any
}

interface TopOccurrence {
  user_uid?: string
  uid?: string
  occurrence_count: number
}

export interface BatterySlopeStatus {
  count: number
  average: number
  sum: number
  status?: string
}

export interface Rollout {
  /**
   * id
   *
   * Example: "a7a1d607-c716-4172-a0ce-bc865181370b"
   */
  id: string

  /**
   * created_at
   *
   * Example: "2020-12-21T09:28:51+00:00"
   */
  created_at: string

  /**
   * updated_at
   *
   * Example: "2020-12-21T09:28:51+00:00"
   */
  updated_at: string

  /**
   * type
   *
   * Example: "otafile"
   */
  type: string

  /**
   * slug
   *
   * Example: "RBLHEXBU"
   */
  slug: string
  info: {
    list?: string
    list_name?: string

    /**
     * actor email
     *
     * Example: "for.example@ouraring.com"
     */
    actor?: string

    /**
     * source
     *
     * Example: "poirot"
     */
    source?: string
  }
  value: {
    /**
     * slug
     *
     * Example: "XLWQOEKZ"
     */
    slug: string

    /**
     * firmware type
     *
     * Example: "firmware_gen2x"
     */
    type: string

    /**
     * version
     *
     * Example: "200.1.85"
     */
    version: string
  }

  /**
   * state
   *
   * Example: "active"
   */
  state: string

  /**
   * flavor
   *
   * Example: "debug"
   */
  flavor: string
  language: string | null
  priority: number
  filters: string[]
  schedule: {
    apply_at: string
    percentile: number
  }[]
  platform: string | null
  min_version: string | null
  max_version: string | null
}

// study = study group = team
export interface Study {
  id: string
  name: string
  appFlavor: string
  type?: string
  fwVersion: string
  ringSizes: string[]
  createdAt: any
  updatedAt: any
  updatedBy: string
  createdBy: string
  managerEmails: string[]
  studyFiles: StudyFile[]
  studyUsers: StudyUser[]
  autoSchedules: AutoSchedule[]
  protocols?: StudyProtocol[]
  memoryLayout: MemoryLayout
  labels?: string[]
  rollouts?: { [key: string]: string[] }
}

export type ProtoFile = {
  /**
   * file id
   *
   * Example: "resting_heart_rate"
   */
  id: string

  /**
   * file name
   *
   * Example: "Resting Heart Rate"
   */
  name: string
  flags: Flag[]
  activities: Activity[]
  createdAt?: any
  updatedAt?: any

  /**
   * created by
   *
   * Example: "example@ouraring.com"
   */
  createdBy?: string

  /**
   * updated by
   *
   * Example: "example@ouraring.com"
   */
  updatedBy?: string
}

export type OtaFile = {
  /**
   * file id
   *
   * Example: "a7a1d607-c716-4172-a0ce-bc865181370b"
   */
  id: string

  /**
   * created_at
   *
   * Example: "2022-12-01T13:04:52+00:00"
   */
  created_at: string
  extra: any

  /**
   * filename
   *
   * Example: "firmware_oreo-0.2.111-PZTQG34Y.zip"
   */
  filename: string

  /**
   * md5
   *
   * Example: "174bf27bae78b48f24ae61f89551b4b5"
   */
  md5: string

  /**
   * sha256
   *
   * Example: "d7c56312420904175e8443f25f5e3ae6d666239f1ea4480a63e876b7acfbc007"
   */
  sha256: string

  /**
   * size
   *
   * Example: 593199
   */
  size: number

  /**
   * slug
   *
   * Example: "PZTQG34Y"
   */
  slug: string

  /**
   * state
   *
   * Example: "verified"
   */
  state: string

  /**
   * type
   *
   * Example: "firmware_oreo"
   */
  type: string

  /**
   * updated_at
   *
   * Example: "2022-12-01T13:04:58+00:00"
   */
  updated_at: string

  /**
   * uploaded_at
   *
   * Example: "2022-12-01T13:04:55+00:00"
   */
  uploaded_at: string

  /**
   * version
   *
   * Example: "0.2.111"
   */
  version: string
}

export enum EnvValues {
  TEST = 'test',
  STAGE = 'stage',
  PROD = 'prod',
}
