<template>
  <v-row>
    <v-col cols="9">
      <div class="text-h5 font-weight-light">
        Scheduled tasks run periodically with predefined settings

        <v-tooltip location="top">
          <template #activator="{ props }">
            <v-btn v-bind="props" link to="/tasks" variant="text" icon="mdi-text-box-search-outline" />
          </template>

          <p>Analyzer task</p>
        </v-tooltip>
      </div>

      <div class="text-subtitle-2 text-grey-darken-2 font-weight-light">
        You can configure automatic alerts for these runs in the schedule alerts tab
      </div>
    </v-col>

    <v-col cols="3" class="d-flex justify-end align-top">
      <v-btn text="Create new" color="primary" @click="newSchedule()" />
    </v-col>
  </v-row>

  <v-row>
    <v-col cols="12">
      <v-sheet>
        <v-data-table
          disable-pagination
          item-key="ruleName"
          no-data-text="There are no scheduled tasks to display"
          :loading="isLoading"
          :headers="scheduleHeaders"
          :items="scheduleTasks"
          :items-per-page="100"
          :items-per-page-options="[
            { title: '100', value: 100 },
            { title: '500', value: 500 },
            { title: '1000', value: 1000 },
          ]"
          :sort-by="[{ key: 'ruleName', order: 'desc' }]"
        >
          <template #item.scheduleExpression="{ item }">
            {{ getCronSchedule(item.scheduleExpression) }}
          </template>

          <template #item.appFlavor="{ item }">
            <span>
              {{ item.targets[0].input.parameters.appFlavor || 'Any' }}
            </span>
          </template>
          <template #item.targets="{ item }">
            Versions:
            {{ item.targets[0].input.parameters.fwVersions || '' }}
            , HW: {{ getHw(item.targets[0].input.parameters.hwType, 'Any') }}, Android:
            {{
              item.targets[0].input.parameters.osSplit
                ? getOs(item.targets[0].input.parameters.osSplit, 'android')
                : '50'
            }}%, iOS:
            {{
              item.targets[0].input.parameters.osSplit ? getOs(item.targets[0].input.parameters.osSplit, 'ios') : '50'
            }}%, Users: {{ item.targets[0].input.parameters.maxUsers || 1000 }}, Devices:
            <span v-if="item.targets[0].input.parameters.deviceModels">
              {{
                item.targets[0].input.parameters.deviceModels
                  ? Array.isArray(item.targets[0].input.parameters.deviceModels)
                    ? item.targets[0].input.parameters.deviceModels.join(';')
                    : item.targets[0].input.parameters.deviceModels
                  : 'Any'
              }}
            </span>
            <span v-else>-</span>
          </template>

          <template #item.command="{ item }">
            <template v-if="item.targets[0].input.parameters.inputCommand === 'tlog'">
              tlog &lt;jzlog&gt; -&lt;os&gt; &lt;target_version&gt;
              {{
                getTlogOptions(
                  item.targets[0].input.parameters.inputOptions,
                  item.targets[0].input.parameters.fwVersionListId,
                )
              }}
            </template>
          </template>

          <template #item.createdBy="{ item }">
            {{ item.targets[0].input.info.scheduleCreator || 'Not available' }}
          </template>

          <template #item.ruleName="{ item }">
            {{ $dayjs(item.ruleName.split('_')[1].split('T')[0]).format('D.M.YYYY') }}
          </template>

          <template #item.state="{ item }">
            <div
              class="font-weight-bold text-no-wrap"
              :class="item.state === 'ENABLED' ? 'text-success' : 'text-warning'"
            >
              {{ item.state }}
            </div>
          </template>

          <template #item.actions="{ item }">
            <v-menu>
              <template #activator="{ props }">
                <v-btn v-bind="props" icon="mdi-dots-vertical" />
              </template>

              <v-list>
                <v-list-item
                  class="text-success"
                  title="Edit schedule"
                  prepend-icon="mdi-pencil"
                  @click="modifySchedule(item)"
                />

                <v-list-item
                  class="text-success"
                  title="Change state"
                  :prepend-icon="item.state === 'ENABLED' ? 'mdi-pause' : 'mdi-play'"
                  @click="modifyScheduleState(item)"
                />

                <v-list-item
                  class="text-error"
                  title="Delete schedule"
                  prepend-icon="mdi-delete"
                  @click="deleteSchedule(item)"
                />
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-sheet>
    </v-col>
  </v-row>

  <EditSchedule
    :env="env"
    :open="editDialogOpen"
    :schedule="selectedSchedule"
    @close="((editDialogOpen = false), (selectedSchedule = null))"
  />

  <v-dialog v-if="selectedSchedule" v-model="stateDialogOpen" width="800">
    <v-card>
      <v-card-title class="headline">Change schedule state</v-card-title>

      <v-card-text>
        <v-select v-model="selectedSchedule.state" :items="scheduleStates" />
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn text="Cancel" class="mr-2" @click="stateDialogOpen = false" />

        <v-btn text="Save" color="primary" @click="saveScheduleState()" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { Component, Prop, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { TaskHelpers } from '#common/TaskHelpers.vue'

  import { scheduleHeaders, scheduleStates } from '#views/setup/constants'

  import { PrefsStore, SetupStore } from '#stores'

  import { ScheduleTask } from '#types'

  @Component
  class SchedulesView extends mixins(TaskHelpers) {
    @Prop() public env!: string

    public editDialogOpen = false
    public stateDialogOpen = false

    public selectedSchedule: ScheduleTask | null = null

    public readonly scheduleStates = scheduleStates
    public readonly scheduleHeaders = scheduleHeaders

    private readonly setupStore = new SetupStore()
    private readonly prefsStore = new PrefsStore()

    public get isLoading() {
      return this.setupStore.loading
    }

    public get scheduleTasks() {
      return this.setupStore.scheduleTasks
    }

    public get daysLimitPrefs() {
      return this.prefsStore.daysLimit
    }

    @Watch('env', { immediate: true })
    protected envChanged() {
      this.setupStore.listScheduleTasks(this.env)
    }

    public newSchedule() {
      this.selectedSchedule = null

      this.editDialogOpen = true
    }

    public modifySchedule(schedule: ScheduleTask) {
      this.editDialogOpen = true

      this.selectedSchedule = { ...schedule }
    }

    public deleteSchedule(schedule: ScheduleTask) {
      this.$confirm('Confirm deletion of this scheduled task?', schedule.description, {
        buttonTrueColor: 'error',
      }).then((confirmed) => {
        if (confirmed) {
          this.setupStore.deleteScheduleTask(this.env, schedule.ruleName)
        }
      })
    }

    public saveScheduleState() {
      this.setupStore.modifyScheduleTask(this.env, this.selectedSchedule!.ruleName, {
        state: this.selectedSchedule!.state,
      })

      this.stateDialogOpen = false

      this.selectedSchedule = null
    }

    public modifyScheduleState(schedule: ScheduleTask) {
      this.stateDialogOpen = true

      this.selectedSchedule = { ...schedule }
    }
  }

  export default toNative(SchedulesView)
</script>
