<template>
  <div>
    <v-row align="center" class="mb-n8">
      <v-col v-if="![taskConfigModes.UPLOAD, taskConfigModes.SCHEDULED].includes(mode) && !isFactory">
        <v-text-field
          persistent-placeholder
          label="Targeted firmwares"
          hint="Max 5, separated by comma"
          placeholder="Type FW versions..."
          :model-value="task.parameters.fwVersions ? task.parameters.fwVersions.trim() : ''"
          @update:model-value="
            task.parameters.fwVersions = $event.trim().replace(/\s/g, ',').replace(/;/g, ',').replace(/,+/g, ',')
          "
        />
      </v-col>

      <v-col v-if="mode === taskConfigModes.RANDOM">
        <v-text-field
          persistent-placeholder
          label="Device models"
          placeholder="e.g. Google Pixel 6;..."
          hint="Separated by semicolon"
          :model-value="task.parameters.deviceModels ? task.parameters.deviceModels.join(';') : ''"
          @update:model-value="
            task.parameters.deviceModels = $event
              .split(';')
              .map((x: string) => x.trim())
              .filter(Boolean)
          "
        />
      </v-col>

      <v-col v-if="mode === taskConfigModes.SELECT && !isFactory">
        <v-select
          v-model="task.parameters.whitelistedFilters"
          clearable
          persistent-placeholder
          item-title="name"
          item-value="uid"
          label="Include rings / users*"
          placeholder="Select sample list..."
          :items="taskSampleFilters"
          :hint="
            task.parameters.whitelistedFilters
              ? 'type: ' + getSampleFilterName(task.parameters.whitelistedFilters, 'type')
              : ''
          "
          :rules="[(v) => !!v || '*This field is required.']"
          @click:clear="task.parameters.whitelistedFilters = ''"
        />
      </v-col>

      <template v-if="isValidFactoryMode">
        <v-col>
          <v-select
            v-model="task.parameters.factory"
            label="Factory*"
            :items="factories.filter((f) => f.value !== '')"
            :rules="[(v) => !!v || '*This field is required.']"
          />
        </v-col>

        <v-col>
          <v-select
            v-model="task.parameters.testPhase"
            label="Test Phase*"
            :items="testPhases"
            :rules="[(v) => !!v || '*This field is required.']"
          />
        </v-col>
      </template>

      <v-col
        v-if="![taskConfigModes.RERUN, taskConfigModes.UPLOAD, taskConfigModes.SCHEDULED].includes(mode) && !isFactory"
      >
        <v-select
          v-model="task.parameters.blacklistedFilters"
          clearable
          persistent-placeholder
          item-title="name"
          item-value="uid"
          label="Exclude rings / users"
          placeholder="Select sample lists..."
          :items="taskSampleFilters"
          @click:clear="delete task.parameters.blacklistedFilters"
        />
      </v-col>

      <template v-if="mode === taskConfigModes.UPLOAD">
        <v-col cols="6">
          <v-file-input
            persistent-hint
            persistent-placeholder
            class="ml-2 mb-4"
            density="compact"
            label="File input (required)"
            placeholder="No file selected..."
            :accept="task.parameters.inputCommand === 'tlog' ? '.json,.csv' : '.csv'"
            :hint="
              task.parameters.inputCommand === 'tlog'
                ? 'File needs to be in CSV or JSON format'
                : 'File needs to be in CSV format'
            "
            :model-value="task.file ? [task.file] : undefined"
            @update:model-value="task.file = $event as File"
          />
        </v-col>

        <v-col cols="3">
          <v-tooltip location="bottom">
            <template #activator="{ props }">
              <div class="text-no-wrap mt-n8">
                <v-icon v-bind="props" color="primary">mdi-information</v-icon>
                JSON example
              </div>
            </template>

            <span>
              <pre>{{ jsonSample }}</pre>
            </span>
          </v-tooltip>
        </v-col>

        <v-col v-if="mode === taskConfigModes.UPLOAD" cols="3">
          <v-tooltip location="bottom">
            <template #activator="{ props }">
              <div class="text-no-wrap mt-n8">
                <v-icon v-bind="props" color="primary" dark>mdi-information</v-icon>
                CSV example
              </div>
            </template>

            <span v-if="task.parameters.inputCommand === 'tlog'">
              439bd3e2-a5d6-4401-90b2-5b09061987ea,2.50.4,gen2,ios,production
            </span>
            <span v-else>
              <p class="mb-0 pb-0">user_id,start_t,end_t</p>
              <p>2eafb6f3-2254-4636-a1c0-272fe81c2813,2.2.2022,2.2.2022</p>
            </span>
          </v-tooltip>
        </v-col>
      </template>

      <v-col v-if="mode === taskConfigModes.SCHEDULED" class="d-flex">
        Sample settings / type is defined by the scheduled task configuration

        <v-spacer />

        <v-btn variant="outlined" color="primary" @click="$router.push(`/setup#schedules`)">
          <v-icon class="mr-2">mdi-cog</v-icon>

          Edit scheduled tasks configuration
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-if="mode === taskConfigModes.RANDOM || isValidFactoryMode" class="mb-n4">
      <v-col>
        <v-select v-model="task.parameters.hwType" hide-details label="HW type" :items="hardwareTypes" />
      </v-col>

      <template v-if="mode === taskConfigModes.RANDOM">
        <v-col>
          <v-select v-model="task.parameters.osSplit" hide-details label="OS type" :items="osSelections" />
        </v-col>

        <v-col>
          <v-combobox
            v-model="task.parameters.maxUsers"
            hide-details
            type="number"
            label="Max users"
            :items="countItems"
            :rules="[maxCount]"
          />
        </v-col>
      </template>
    </v-row>

    <v-row v-if="isValidFactoryMode">
      <template v-if="!isSchedule">
        <v-col>
          <v-menu
            v-model="startDateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template #activator="{ props }">
              <v-text-field
                v-bind="props"
                v-model="startDateFormatted"
                readonly
                label="Start date"
                hide-details="auto"
                style="text-align: right"
                prepend-icon="mdi-calendar"
              />
            </template>

            <v-date-picker
              no-title
              hide-actions
              :model-value="task.parameters.timestampFrom ? new Date(task.parameters.timestampFrom) : undefined"
              @update:model-value="
                ((task.parameters.timestampFrom = $dayjs($event).format('YYYY-MM-DDTHH:mm:ss+00:00')),
                (startDateMenu = false))
              "
            />
          </v-menu>
        </v-col>

        <v-col>
          <v-menu
            v-model="endDateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template #activator="{ props }">
              <v-text-field
                v-bind="props"
                v-model="endDateFormatted"
                readonly
                label="End date"
                hide-details="auto"
                style="text-align: right"
                prepend-icon="mdi-calendar"
              />
            </template>

            <v-date-picker
              no-title
              hide-actions
              :model-value="task.parameters.timestampTo ? new Date(task.parameters.timestampTo) : undefined"
              @update:model-value="
                ((task.parameters.timestampTo = $dayjs($event).format('YYYY-MM-DDTHH:mm:ss+00:00')),
                (endDateMenu = false))
              "
            />
          </v-menu>
        </v-col>
      </template>

      <v-col v-if="isSchedule" cols="6">
        <v-select
          v-model="task.parameters.samplePeriodDays"
          persistent-hint
          label="Timespan"
          hint="Number of FULL DAYS to analyze. First day is previous day."
          :items="samplePeriodDays"
        />
      </v-col>
    </v-row>

    <v-row class="my-n2">
      <v-col v-if="!isFactory">
        <v-checkbox
          hide-details
          :model-value="!!task.parameters.blacklistedLabels"
          @update:model-value="
            $event
              ? (task.parameters.blacklistedLabels = 'outo_custom:poirot_exclude')
              : delete task.parameters.blacklistedLabels
          "
        >
          <template #label>
            <div class="ml-2">Exclude users with testing labels</div>
          </template>
        </v-checkbox>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { RING_STATE, getRingTypesByState } from '@jouzen/outo-toolkit-vuetify'

  import { TaskHelpers } from '#common/TaskHelpers.vue'

  import { jsonSample, osSelections, samplePeriodDays, testPhases } from '#views/tasks/constants'
  import { factories } from '#views/tests/constants'

  import { SetupStore } from '#stores'

  import { TaskConfigModes, TaskSettings } from '#types'

  @Component
  class TaskConfig extends mixins(TaskHelpers) {
    @Prop({ required: true }) public source!: string
    @Prop({ required: true }) public task!: TaskSettings
    @Prop({ required: true }) public mode!: TaskConfigModes

    @Prop({ default: false }) public isSchedule!: boolean

    public endDateMenu = false
    public startDateMenu = false

    public endDateFormatted = ''
    public startDateFormatted = ''

    public jsonSample = jsonSample
    public osSelections = osSelections

    public readonly factories = factories
    public readonly testPhases = testPhases
    public readonly taskConfigModes = TaskConfigModes
    public readonly samplePeriodDays = samplePeriodDays

    public readonly hardwareTypes = getRingTypesByState([
      ~RING_STATE.DEPRECATED,
      RING_STATE.INTERNAL | RING_STATE.RELEASED,
    ])

    private readonly setupStore = new SetupStore()

    public get countItems() {
      return parseInt(this.task.parameters.maxUsers) <= 500 ? [100, 250, 500] : [100, 1000, 2500, 5000, 10000]
    }

    public get taskSampleFilters() {
      return this.setupStore.sampleFilters.filter((f: any) => f.type !== 'fw_version')
    }

    public get isFactory() {
      return this.source === 'factory'
    }

    public get isValidFactoryMode() {
      return this.mode === TaskConfigModes.SELECT && this.isFactory
    }

    @Watch('mode', { immediate: true })
    protected modeChanged() {
      this.task.parameters.analyzeSampleMode = this.mode

      if (!this.isFactory) {
        delete this.task.parameters.factory
        delete this.task.parameters.samplePeriodDays
        delete this.task.parameters.testPhase
      }

      if (this.mode !== TaskConfigModes.RERUN) {
        if (this.mode !== TaskConfigModes.RANDOM) {
          delete this.task.parameters.hwType
          delete this.task.parameters.osSplit
          delete this.task.parameters.deviceModels
        } else {
          if (!this.task.parameters.hwType) {
            this.task.parameters.hwType = 'oreo'
          }

          if (!this.task.parameters.osSplit) {
            this.task.parameters.osSplit = 'ios:50'
          }

          if (!this.task.parameters.deviceModels) {
            this.task.parameters.deviceModels = []
          }
        }

        if (this.mode !== TaskConfigModes.SELECT) {
          delete this.task.parameters.whitelistedFilters
        }

        if (this.mode === TaskConfigModes.UPLOAD) {
          delete this.task.parameters.blacklistedFilters
        }
      }
    }

    @Watch('task.parameters.timestampTo', { immediate: true })
    protected timestampToChanged() {
      this.endDateFormatted = this.task.parameters.timestampTo
        ? this.$dayjs(this.task.parameters.timestampTo).format('YYYY-MM-DD')
        : ''
    }

    @Watch('task.parameters.timestampFrom', { immediate: true })
    protected timestampFromChanged() {
      this.startDateFormatted = this.task.parameters.timestampFrom
        ? this.$dayjs(this.task.parameters.timestampFrom).format('YYYY-MM-DD')
        : ''
    }

    public maxCount(value: number) {
      return (
        value <= parseInt(this.task.parameters.maxUsers) || `Excess max users limit (${this.task.parameters.maxUsers})`
      )
    }
  }

  export default toNative(TaskConfig)
</script>
